/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    h3: "h3",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "What's been going on"), "\n", React.createElement(_components.h2, null, "Amos Lee"), "\n", React.createElement(_components.h3, null, "Key: C"), "\n", React.createElement(_components.h3, null, "Tempo: 74"), "\n", React.createElement("p", null, "Non-ASCAP"), "\n", React.createElement(_components.p, null, "Fmaj7             C\nAnd no one says a word to me\nFmaj7                     C\nThey're concerned with my jealousy\nFmaj7                        C\nWell I guess that's how it's gotta be\nG\nFrom now on"), "\n", React.createElement(_components.p, null, "Fmaj7                 C\nAnd time, it swallows everything\nFmaj7                  C\nFrom the mighty to the meager thing\nFmaj7                     C\nAnd it's as dark as it is comforting\nG\nTo play along"), "\n", React.createElement(_components.p, null, "F\nWell What's Been Going On?\nC\nWhat's Been Going On?\nF\nWhat's been going on?\nG\nAround here"), "\n", React.createElement(_components.p, null, "Fmaj7             C\nThere goes her old beat up car\nFmaj7             C\nOutside of our old favorite bar\nFmaj7             C\nShe's proudly in there playing her guitar\nG\nWith stars in her eyes\nFmaj7             C\nThose are some of my favorite memories\nFmaj7             C\nAll of those carefree melodies\nFmaj7             C\nWhile I'm out of here on this raging sea\nG\nAbout to capsize"), "\n", React.createElement(_components.p, null, "F\nWell What's Been Going On?\nC\nWhat's Been Going On?\nF\nWhat's been going on?\nG\nAround here\nF\nWell What's Been Going On?\nC\nWhat's Been Going On?\nF\nWhat's been going on?\nG\nAround here"), "\n", React.createElement(_components.p, null, "Fmaj7             C\nAnd no one says a word to me\nFmaj7             C\nThey're concerned with my jealousy\nFmaj7             C\nI guess that's how it's gotta be\nG\nFrom now on"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
